@font-face {
  font-family: Franklin Gothic URW;
  font-weight: 400;
  src: url("37D896_0_0.384ec8af.eot");
  src: url("37D896_0_0.384ec8af.eot#iefix") format("embedded-opentype"), url("37D896_0_0.fde4be23.woff2") format("woff2"), url("37D896_0_0.dc035f89.woff") format("woff"), url("37D896_0_0.7a76905e.ttf") format("truetype");
}

@font-face {
  font-family: Franklin Gothic URW;
  font-weight: 400;
  font-style: italic;
  src: url("37D896_1_0.a1094a84.eot");
  src: url("37D896_1_0.a1094a84.eot#iefix") format("embedded-opentype"), url("37D896_1_0.fff37445.woff2") format("woff2"), url("37D896_1_0.d6080530.woff") format("woff"), url("37D896_1_0.5f3021f2.ttf") format("truetype");
}

@font-face {
  font-family: Franklin Gothic URW;
  font-weight: 600;
  src: url("37D896_2_0.5ecd736f.eot");
  src: url("37D896_2_0.5ecd736f.eot#iefix") format("embedded-opentype"), url("37D896_2_0.c5d95741.woff2") format("woff2"), url("37D896_2_0.ffbe829e.woff") format("woff"), url("37D896_2_0.683eb2a8.ttf") format("truetype");
}

@font-face {
  font-family: HCo Gotham;
  src: url("Gotham-Thin_Web.c980fff8.woff2") format("woff2"), url("Gotham-Thin_Web.672e0b1b.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: HCo Gotham;
  src: url("Gotham-ThinItalic_Web.b3df080d.woff2") format("woff2"), url("Gotham-ThinItalic_Web.edf83593.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: HCo Gotham;
  src: url("Gotham-XLight_Web.c83b829e.woff2") format("woff2"), url("Gotham-XLight_Web.fc205c37.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: HCo Gotham;
  src: url("Gotham-XLightItalic_Web.63bf3bb1.woff2") format("woff2"), url("Gotham-XLightItalic_Web.e7d3b20d.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: HCo Gotham;
  src: url("Gotham-Light_Web.c36c30b8.woff2") format("woff2"), url("Gotham-Light_Web.038ec18e.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: HCo Gotham;
  src: url("Gotham-LightItalic_Web.d291c296.woff2") format("woff2"), url("Gotham-LightItalic_Web.e103393a.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: HCo Gotham;
  src: url("Gotham-Book_Web.5b2c1429.woff2") format("woff2"), url("Gotham-Book_Web.0e00d58e.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: HCo Gotham;
  src: url("Gotham-BookItalic_Web.9b313699.woff2") format("woff2"), url("Gotham-BookItalic_Web.aed5cd63.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: HCo Gotham;
  src: url("Gotham-Medium_Web.a35a5f1c.woff2") format("woff2"), url("Gotham-Medium_Web.1e53ba23.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: HCo Gotham;
  src: url("Gotham-MediumItalic_Web.dfa34f63.woff2") format("woff2"), url("Gotham-MediumItalic_Web.e379d588.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: HCo Gotham;
  src: url("Gotham-Bold_Web.9347b059.woff2") format("woff2"), url("Gotham-Bold_Web.05cbd6a3.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: HCo Gotham;
  src: url("Gotham-BoldItalic_Web.37393903.woff2") format("woff2"), url("Gotham-BoldItalic_Web.a777d1af.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: HCo Gotham;
  src: url("Gotham-Black_Web.80ac3569.woff2") format("woff2"), url("Gotham-Black_Web.6379c07b.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: HCo Gotham;
  src: url("Gotham-BlackItalic_Web.a6e62eb4.woff2") format("woff2"), url("Gotham-BlackItalic_Web.89c19918.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: HCo Gotham;
  src: url("Gotham-Ultra_Web.544030b7.woff2") format("woff2"), url("Gotham-Ultra_Web.5cf233ca.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: HCo Gotham;
  src: url("Gotham-UltraItalic_Web.517e9433.woff2") format("woff2"), url("Gotham-UltraItalic_Web.b6ac15dc.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

/*# sourceMappingURL=postcard-digital.e472b641.css.map */
