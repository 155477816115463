/**
 * Franklin
 */

@font-face {
	font-family: 'Franklin Gothic URW';
	font-weight: 400;
	src: url('../../assets/fonts/franklin/37D896_0_0.eot');
	src: url('../../assets/fonts/franklin/37D896_0_0.eot?#iefix')
			format('embedded-opentype'),
		url('../../assets/fonts/franklin/37D896_0_0.woff2') format('woff2'),
		url('../../assets/fonts/franklin/37D896_0_0.woff') format('woff'),
		url('../../assets/fonts/franklin/37D896_0_0.ttf') format('truetype');
}

@font-face {
	font-family: 'Franklin Gothic URW';
	font-weight: 400;
	font-style: italic;
	src: url('../../assets/fonts/franklin/37D896_1_0.eot');
	src: url('../../assets/fonts/franklin/37D896_1_0.eot?#iefix')
			format('embedded-opentype'),
		url('../../assets/fonts/franklin/37D896_1_0.woff2') format('woff2'),
		url('../../assets/fonts/franklin/37D896_1_0.woff') format('woff'),
		url('../../assets/fonts/franklin/37D896_1_0.ttf') format('truetype');
}

@font-face {
	font-family: 'Franklin Gothic URW';
	font-weight: 600;
	src: url('../../assets/fonts/franklin/37D896_2_0.eot');
	src: url('../../assets/fonts/franklin/37D896_2_0.eot?#iefix')
			format('embedded-opentype'),
		url('../../assets/fonts/franklin/37D896_2_0.woff2') format('woff2'),
		url('../../assets/fonts/franklin/37D896_2_0.woff') format('woff'),
		url('../../assets/fonts/franklin/37D896_2_0.ttf') format('truetype');
}

/**
 * Gotham
 */

@font-face {
	font-family: 'HCo Gotham';
	src: url('../../assets/fonts/gotham/woff2/Gotham-Thin_Web.woff2')
			format('woff2'),
		url('../../assets/fonts/gotham/woff/Gotham-Thin_Web.woff') format('woff');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Gotham';
	src: url('../../assets/fonts/gotham/woff2/Gotham-ThinItalic_Web.woff2')
			format('woff2'),
		url('../../assets/fonts/gotham/woff/Gotham-ThinItalic_Web.woff')
			format('woff');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'HCo Gotham';
	src: url('../../assets/fonts/gotham/woff2/Gotham-XLight_Web.woff2')
			format('woff2'),
		url('../../assets/fonts/gotham/woff/Gotham-XLight_Web.woff') format('woff');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Gotham';
	src: url('../../assets/fonts/gotham/woff2/Gotham-XLightItalic_Web.woff2')
			format('woff2'),
		url('../../assets/fonts/gotham/woff/Gotham-XLightItalic_Web.woff')
			format('woff');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'HCo Gotham';
	src: url('../../assets/fonts/gotham/woff2/Gotham-Light_Web.woff2')
			format('woff2'),
		url('../../assets/fonts/gotham/woff/Gotham-Light_Web.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Gotham';
	src: url('../../assets/fonts/gotham/woff2/Gotham-LightItalic_Web.woff2')
			format('woff2'),
		url('../../assets/fonts/gotham/woff/Gotham-LightItalic_Web.woff')
			format('woff');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'HCo Gotham';
	src: url('../../assets/fonts/gotham/woff2/Gotham-Book_Web.woff2')
			format('woff2'),
		url('../../assets/fonts/gotham/woff/Gotham-Book_Web.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Gotham';
	src: url('../../assets/fonts/gotham/woff2/Gotham-BookItalic_Web.woff2')
			format('woff2'),
		url('../../assets/fonts/gotham/woff/Gotham-BookItalic_Web.woff')
			format('woff');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'HCo Gotham';
	src: url('../../assets/fonts/gotham/woff2/Gotham-Medium_Web.woff2')
			format('woff2'),
		url('../../assets/fonts/gotham/woff/Gotham-Medium_Web.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Gotham';
	src: url('../../assets/fonts/gotham/woff2/Gotham-MediumItalic_Web.woff2')
			format('woff2'),
		url('../../assets/fonts/gotham/woff/Gotham-MediumItalic_Web.woff')
			format('woff');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'HCo Gotham';
	src: url('../../assets/fonts/gotham/woff2/Gotham-Bold_Web.woff2')
			format('woff2'),
		url('../../assets/fonts/gotham/woff/Gotham-Bold_Web.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Gotham';
	src: url('../../assets/fonts/gotham/woff2/Gotham-BoldItalic_Web.woff2')
			format('woff2'),
		url('../../assets/fonts/gotham/woff/Gotham-BoldItalic_Web.woff')
			format('woff');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'HCo Gotham';
	src: url('../../assets/fonts/gotham/woff2/Gotham-Black_Web.woff2')
			format('woff2'),
		url('../../assets/fonts/gotham/woff/Gotham-Black_Web.woff') format('woff');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Gotham';
	src: url('../../assets/fonts/gotham/woff2/Gotham-BlackItalic_Web.woff2')
			format('woff2'),
		url('../../assets/fonts/gotham/woff/Gotham-BlackItalic_Web.woff')
			format('woff');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'HCo Gotham';
	src: url('../../assets/fonts/gotham/woff2/Gotham-Ultra_Web.woff2')
			format('woff2'),
		url('../../assets/fonts/gotham/woff/Gotham-Ultra_Web.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Gotham';
	src: url('../../assets/fonts/gotham/woff2/Gotham-UltraItalic_Web.woff2')
			format('woff2'),
		url('../../assets/fonts/gotham/woff/Gotham-UltraItalic_Web.woff')
			format('woff');
	font-weight: 900;
	font-style: italic;
}
